import React, { useState,useEffect } from 'react';

const Intervention = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <section class="ah-content-section main-abtsec">

            <div class="container">

                <div class="row align-items-center">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <img src="images/in-1.jpg" />
                    </div>
                    <div class="col-lg-6">

                        <h2>Minds In Motion ABA Therapy Center</h2>

                        <p>Minds In Motion ABA Therapy Center, LLC offers comprehensive behavior analytic services for children with Autism Spectrum Disorder and related developmental disabilities and ADHD. Our primary areas of specialization focus on early intervention, language acquisition, school support, social skill development, gross and fine motor skill acquisition. We utilize an array of interventions including Verbal Behavior Development, Natural Environment Training, and Discrete Trial Training in an environment that mimics a preschool. Children are provided with opportunities to play and interact with their peers throughout the day while receiving intense goal driven interventions. </p>

                    </div>
                </div>
                <div class="row align-items-center mt-4 mt-lg-5">
                    <div class="col-lg-6 order-2 order-lg-1">

                        <h2>Center-Based Program</h2>

                        <p class="mb-4">Minds In Motion ABA Therapy Center, LLC boasts a variety of child-friendly spaces in which to learn, play and develop. We incorporate state of the art technology through the BEAM Interactive Virtual Playground ™  where your child will enjoy learning to play with others. We offer large classrooms that mirror a traditional pre-school setting and smaller shared therapy spaces for those who need a quieter place in which to learn.  Throughout the day your child will have opportunities to engage with their peers in our Sensory Gym, to increase social, play and motor skills. Our Center includes thematic play rooms where we incorporate music, movement, fine motor skills, and fun!. </p>

                        <p class="mb-4">We welcome parents to observe therapy sessions via cameras in each therapy area. </p>


                    </div>
                    <div class="col-lg-6 order-1 order-lg-2 mb-4 mb-lg-0 mt-3 mt-lg-0"><img src="images/in-2.jpg" /></div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <p class="mb-4">The goal of our Center-based program is to jump start each child’s verbal behavior, peer interaction  and social/emotional skills by developing a individualized intervention plan specific to your child. Each of our center-based programs is committed to supporting students in the least restrictive environment possible, while ensuring educational and treatment gains. We focus on the integration of the child into their home school. This eventual transition is planned from enrollment and assessed at every monthly review meeting.</p>

                        <p>Daily supervision by a Board Certified Behavior Analyst (BCBA) and teacher ensures that your child is on track to meet his/her goals. Our staff is comprised of highly-trained BCBAs and RBTs who receive intensive instruction and hands on training before delivering ABA treatment to . Our clinical team works collaboratively with you to continually assess and update your child’s development plan at the pace guided by your child. Working collaboratively gives us the ability to look at a child through multiple lenses and build skills that transcend all areas of development. We strive to create a stimulating environment where our clients feel safe and secure, where their self-esteem is nurtured and each child can unfold their personality, learn and master challenges. We build on each child’s strengths and devise Individual Treatment Plans so that the child can develop their individuality and talents to achieve their full potential.</p>

                    </div>
                </div>
                <div class="row align-items-center mt-4 mt-lg-5">

                    <div class="col-lg-6 mb-4 mb-lg-0 mt-3 mt-lg-0"><img src="images/home-aba.jpg" /></div>
                    <div class="col-lg-6">
                        <h2>In-home ABA Programs</h2>
                        <p class="">In-home ABA services provide an opportunity to teach your child daily living skills in the natural environment. This enables the BCBA and RBT to implement interventions that decrease targeted behaviors and increase developmentally appropriate skills. <br />Your child’s RBT will be supervised by their BCBA, who will always be available to you for questions or concerns. A BCBA typically comes out once or twice a week to model strategies and supervise the RBT as well as provide parent training. In-home services can be in conjunction with Center-based or in school services. </p>


                    </div>
                </div>
                <div class="row align-items-center mt-4 mt-lg-5">
                    <div class="col-lg-6 order-2 order-lg-1">

                        <h2>Board Certified Behavior Analytic Services</h2>

                        <p class="">A Board Certified Behavior Analyst (BCBA) provides professional behavior analytic services o the client, family, and caregivers at our center, home, school, and in the community.  The BCBA and Registered Behavior Technician (RBT) utilize the principles of Applied Behavior Analysis (ABA) to increase language, social, academic, and functional life skills. </p>
                    </div>
                    <div class="col-lg-6 order-1 order-lg-2 mb-4 mb-lg-0 mt-3 mt-lg-0"><img src="images/board.jpg" /></div>
                </div>
                <div class="row align-items-center mt-4 mt-lg-5">
                    <div class="col-lg-6 mb-4 mb-lg-0 mt-3 mt-lg-0"><img src="images/MIM Globe.jpg" /></div>
                    <div class="col-lg-6">

                        <h2>School District Consultation &amp; Private School Consultation</h2>

                        <p class="">Interventions based on the principles of Applied Behavior Analysis (ABA) are effective for increasing prosocial behaviors and decreasing challenging behavior in the school setting. Consultations include observing your child in the classroom and within the school community to gain a better understanding of your child's academic and social/emotional challenges. The BCBA partners with the parents, teachers, administrators, occupational therapists, and speech and language pathologists to develop a comprehensive school-based intervention plan to support your child. All School District and Private School Consultations are conducted by our BCBA Clinical Director and Co-Founder.</p>

                    </div>
                </div>
                <div class="row align-items-center mt-4 mt-lg-5">
                    <div class="col-lg-6 order-2 order-lg-1">

                        <h2>Parent Training </h2>

                        <p class="">An important component in all our programs is Parent Training, Understanding how to create opportunities to encourage your child to communicate or how to respond to your child’s behavior can be challenging. Minds in Motion ABA Therapy Center LLC is here to help empower you to create the change you want to see in your child. We create parent training programs to meet your unique family needs. Parent Training is led by your child's BCBA and may take place at home or at our Center.  It is also a time to ask any questions you have and to receive resources that will help you, your child and your family. Parent Training and a review of your child’s progress takes place at least once a month.</p>

                    </div>
                    <div class="col-lg-6 order-1 order-lg-2 mb-4 mb-lg-0 mt-3 mt-lg-0"><img src="images/Parent Training.jpg" /></div>
                </div>
                <div class="row align-items-center mt-4 mt-lg-5">
                    <div class="col-lg-6 mb-4 mb-lg-0 mt-3 mt-lg-0"><img src="images/BCBA Supervision .jpg" /></div>
                    <div class="col-lg-6">

                        <h2>BCBA/BCaBA/RBT Supervision</h2>

                        <p class="">Minds In Motion ABA Therapy Center, LLC  provides supervision to individuals interested in obtaining their BCBA, BCaBA, and RBT certificate. Ongoing supervision for BCaBA’s and RBTs is also provided.</p>

                    </div>

                </div>

            </div>

        </section>
    );
}

export default Intervention;