import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import About from "./About"
import Blog from "./Blog"
import Careers from "./Careers"
import Client from "./Client"
import Covid from "./Covid"
import Intervention from "./Intervention"
import Referrals from "./Referrals"
import Resources from "./Resources"
import Services from "./Services"
import Social from "./Social"
import Team from "./Team"
import Home from "./Home"
import Header from "./Component/Header"
import Footer from "./Component/Footer"

function App() {
  return (
    <Router>
      <Header />
      <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/careers">
            <Careers />
          </Route>
          <Route path="/referrals">
            <Referrals />
          </Route>
          <Route path="/client">
            <Client />
          </Route>
          <Route path="/covid-19-policy">
            <Covid />
          </Route>
          <Route path="/intervention-services">
            <Intervention />
          </Route>
          <Route path="/resources">
            <Resources />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/social-skills-group">
            <Social />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
    </Router>
  );
}

export default App;
