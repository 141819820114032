import React, { useState, useEffect, useRef } from 'react';
import {
  Link,
  NavLink
} from "react-router-dom";
import tawkTo from "tawkto-react";

const tawkToPropertyId = '60df5409d6e7610a49a9551d';

// Direct Chat Link
// https://tawk.to/chat/tawkToPropertyId/tawkToKey

const tawkToKey = '1f9k60hrt';

const Header = () => {
  const supportnav = useRef();
  const removeshownav = () => {
    supportnav.current.classList.remove('show');
  }
  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey)
  }, [])

  return (
    <header class="wow fadeInDown" data-wow-delay="0.5s">
      <div class="top-line">
        <span><marquee behavior="" direction="">Insurances we accept: Aetna, United Healthcare, Florida Blue, and Cigna.</marquee></span>
        <img src="images/top-line.png" class="w-100" alt="img" />
      </div>
      <div class="social-rw">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-3 col-sm-3">
              <Link className="d-none d-sm-none d-md-block nav-logo" to="/"><img src="images/mim-logo.png" class="img-fluid" alt="img" /></Link>
            </div>
            <div class="col-lg-9 col-sm-9">
              <div class="socl-lnk">
                <ul>
                  <li class="d-flex d-md-block">
                    <div class="eml-icn">
                      <a href="tel:9549880918"><i class="fas fa-phone-alt"></i></a>
                      <div class="eml-txt">
                        <h2 class="d-none">Phone:</h2>
                        <a class="d-none d-md-flex" href="tel:9549880918">954-988-0918</a>
                      </div>
                    </div>
                    <div class="eml-icn d-flex d-md-none">
                      <a href="mailto:info@mindsinmotionaba.com"><i class="fal fa-envelope"></i></a>
                      <div class="eml-txt">
                        <h2 class="d-none">E-mail:</h2>
                        <a class="eml d-none d-md-flex" href="mailto:info@mindsinmotionaba.com">info@mindsinmotionaba.com</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="eml-icn d-none d-md-flex">
                      <a href="mailto:info@mindsinmotionaba.com"><i class="fal fa-envelope"></i></a>
                      <div class="eml-txt">
                        <h2 class="d-none">E-mail:</h2>
                        <a class="eml" href="mailto:info@mindsinmotionaba.com">info@mindsinmotionaba.com</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="socl-icn">
                      <a href="https://www.facebook.com/mindsinmotionaba" target="_blank"><i class="fab fa-facebook-f"></i></a>
                      <a href="https://www.instagram.com/mindsinmotionaba/" target="_blank"><i class="fab fa-instagram"></i></a>
                      <a href="https://www.linkedin.com/in/mimaba" target="_blank"><i class="fab fa-linkedin"></i></a>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="addrMain mt-3 d-none">
                <a href="https://g.page/mimabatherapycenter?share" target="_blank">
                  <div class="eml-icn">
                    <i class="fas fa-map-marker-alt"></i>
                    <div class="eml-txt">
                      <h2>Address:</h2>
                      <span class="eml">9070 Kimberly Blvd Ste. 19-20 Boca Raton, FL 33433</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-navigate">
        <div class="an-navbar">
          <div class="container">
            <nav class="navbar navbar-expand-md">
              <Link className="navbar-brand d-sm-block d-md-none d-lg-none" to="/">
                <img src="images/mim-logo.png" alt="" />
              </Link>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="fa fa-bars"></span>
              </button>
              <div class="collapse navbar-collapse" ref={supportnav} id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item" onClick={removeshownav} >
                    <NavLink id="nav-home" className="nav-link" activeClassName="active" to="/" exact>Home <span class="sr-only">(current)</span></NavLink>
                  </li>
                  <li class="nav-item" onClick={removeshownav}>
                    <NavLink id="nav-about" className="nav-link" activeClassName="active" to="/about" exact>About Us</NavLink>
                  </li>
                  <li class="nav-item" onClick={removeshownav}>
                    <NavLink id="nav-intervention" className="nav-link" activeClassName="active" to="/intervention-services" exact>Services</NavLink>
                  </li>
                  <li class="nav-item" onClick={removeshownav}>
                    <NavLink id="nav-services" className="nav-link" activeClassName="active" to="/services" exact>Assessments</NavLink>
                  </li>
                  <li class="nav-item" onClick={removeshownav}>
                    <NavLink id="nav-team" className="nav-link" activeClassName="active" to="/social-skills-group" exact>Social Skills</NavLink>
                  </li>
                  <li class="nav-item" onClick={removeshownav}>
                    <NavLink id="nav-team" className="nav-link" activeClassName="active" to="/team" exact>Team</NavLink>
                  </li>
                  <li class="nav-item" onClick={removeshownav}>
                    <NavLink id="nav-careers" className="nav-link" activeClassName="active" to="/careers" exact>Careers</NavLink>
                  </li>
                  <li class="nav-item" onClick={removeshownav}>
                    <NavLink id="nav-referrals" className="nav-link" activeClassName="active" to="/referrals" exact>Referrals</NavLink>
                  </li>
                </ul>
                <div class="form-inline my-2 my-lg-0">
                  <a href="mailto:info@mindsinmotionaba.com" className="an-btn light mr-3">Contact</a>
                  {/* <NavLink className="an-btn light mr-3" to="/client">Contact</NavLink> */}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;