import React from 'react';
const GoogleMaps = ({ latitude, longitude }) => {

 return (
   <div style={{ height: '80vh', width: '100%' }}>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3574.2893533367223!2d-80.19061548549087!3d26.38183788909368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9191a1635ca13%3A0xdeff3c7c8ebd821d!2sMinds%20in%20Motion%20ABA%20Therapy%20Center%2C%20LLC!5e0!3m2!1sen!2sin!4v1628659388531!5m2!1sen!2sin" width="100%" height="100%" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
   </div>
 );
};

export default GoogleMaps;