import React, { useState } from 'react';
import {
  Link
} from "react-router-dom";


const Footer = () => {

  return (
    
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-sm-4 pr-5">
            <img src="images/mim-logo.png" class="img-fluid" alt="img" />
          </div>
          <div class="col-lg-4 col-sm-4 pl-md-5">
            <div class="ftr-txt">
              <h2>Quick Links:</h2>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/intervention-services">Services</Link></li>
                <li><Link to="/services">Assessment</Link></li>
                <li><Link to="/social-skills-group">Social Skills</Link></li>
                <li><Link to="/team">Our Team </Link></li>
                <li><Link to="/careers">Careers </Link></li>
                <li><Link to="/referrals">Referrals</Link></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="ftr-txt">
              <h2>Contact Info:</h2>
            </div>
            <div class="ftr-tlphn">
              <span>
                <figure>
                  <img src="images/call.png" class="img-fluid" alt="img" />
                </figure>
              </span>
              <div class="ml-3">
                <h3>PHONE:</h3>
                <a href="tel:9549880918">954-988-0918</a>
              </div>
            </div>
            <div class="ftr-tlphn">
              <span class="emlasst">
                <figure>
                  <img src="images/mail.png" class="img-fluid" alt="img" />
                </figure>
              </span>
              <div class="ml-3">
                <h3>EMAIL:</h3>
                <a href="mailto:info@mindsinmotionaba.com">info@mindsinmotionaba.com</a>
              </div>
            </div>
            <div class="ftr-tlphn">
              <span class="emlasst blue-border">
                <figure>
                  <img src="images/map.png" class="img-fluid" alt="img" />
                </figure>
              </span>
              <div class="ml-3">
                <h3>Address:</h3>
                <a href="https://g.page/mimabatherapycenter?share" target="_blank">9070 Kimberly Blvd Ste. 19-20 Boca Raton, FL 33433</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="cpyrght">
              <p>&copy; 2024 Minds In Motion ABA Therapy Center, LLC. All Right Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;