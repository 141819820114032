import React, { useState,useEffect } from 'react';

const Careers = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>
            <section class="banner career-banner">

                <div class="container">

                    <div class="row">

                        <div class="col-md-12">

                            <h2>Careers</h2>

                        </div>

                    </div>

                </div>

            </section>





            <section class="main-carerpag">

                <div class="container">

                    <div class="row">

                        <div class="col-md-12">

                            <div class="carerpag-txt">

                                <p class="pr-0 mailTxt">We welcome you to submit your resumes via email to <a href="mailto:info@mindsinmotionaba.com">info@mindsinmotionaba.com</a>. For any inquiries or to discuss potential opportunities further, please feel free to reach out to us directly at <a href="tel:954-988-0918">954-988-0918</a>. We look forward to connecting with you.</p><br/>

                                <h2>About Minds In Motion ABA Therapy Center</h2>

                                <p class="pr-0">Minds in Motion ABA Therapy Center is dedicated to providing high-quality, ethical, and innovative ABA therapy to children on the autism spectrum and other related developmental disabilities. Specializing in the principles of Radical Behaviorism, we are committed to setting a standard of care in the field. Our mission is not only to help children gain valuable skills but also to support their families in leading fulfilled and successful lives together.</p><br/>

                                <h2>Join Our Team</h2>

                                <p class="pr-0">Minds In Motion ABA Therapy Center, is always searching for motivated, enthusiastic individuals with a desire to help kids succeed and learn more about the field of Applied Behavior Analysis (ABA). As our caseload grows, we are constantly considering candidates for the position of ABA Therapist/Behavioral Technician.</p><br/>

                                <p class="pr-0">We hire year-round and maintain an ongoing consideration of candidates for various positions. Your resume/application will be kept on file, and we'll contact you as new opportunities arise. If you are a professional, outgoing, energetic, and caring individual, we invite you to join us in making a difference in the lives of children.</p>





                            </div>




                            <div class="faq">

                                <div id="accordion" class="accordionStyle">

                                <div class="card mb-0">

                                    <div class="card-header" id="headingOne">

                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Accreditation <i class="fas fa-plus"></i></button>

                                    </div>

                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">

                                        <div class="card-body">

                                            <p>At Minds in Motion ABA Therapy Center, we are proud to hold the Behavioral Health Center of Excellence (BHCOE) Accreditation®. This accreditation underscores our unwavering commitment to maintaining the highest standards of quality and our dedication to continual improvement in the field of applied behavior analysis. It serves as a testament to our relentless pursuit of excellence in providing top-tier care for children on the autism spectrum and those with related developmental disabilities.</p>
                                            <a href="/pdf/mim-work-application.pdf" target="_blank" class="an-btn mt-1 d-inline-block">Apply Now</a>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="card mb-0">

                                        <div class="card-header" id="headingTwo">

                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Why choose MIM? <i class="fas fa-plus"></i></button>

                                        </div>

                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">

                                            <div class="card-body">

<ol>
    <li>If you’re a BT/RBT</li>
    <ol>
        <li>&bull; A positive and supportive team culture</li>
        <li>&bull; Supervisions weekly by BCBA</li>
        <li>&bull; Student Analyst Training Program</li>
        <li>&bull; 1-Week of New Hour Training</li>
        <li>&bull; 40-hour RBT Course</li>
        <li>&bull; Paid Select Holiday after one year of employment</li>
        <li>&bull; Advancement Opportunities</li>
        <li>&bull; Paid Referral Program</li>
        <li>&bull; Other benefits may apply</li>
    </ol><br/>
    <li>If you’re a BCBA/BCaBA</li>
    <ol>
        <li>&bull; Two Weeks of Paid Time Off (PTO)</li>
        <li>&bull; Paid Sick Time</li>
        <li>&bull; Paid CEUs</li>
        <li>&bull; Paid Holidays</li>
        <li>&bull; Other benefits may apply</li>
    </ol>
</ol>

                                                <a href="/pdf/mim-work-application.pdf" target="_blank" class="an-btn mt-1 d-inline-block">Apply Now</a>
                                            </div>

                                        </div>

                                    </div>



                                    <div class="card mb-0">

                                        <div class="card-header" id="headingThree">

                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Equal Employment Opportunity Employer <i class="fas fa-plus"></i></button>

                                        </div>

                                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">

                                            <div class="card-body">

                                                <p>At Minds in Motion ABA Therapy Center, LLC, we are proud to be an Equal Opportunity Employer. Our employment opportunities are determined by an individual's qualifications and ability to perform the essential functions of a specific job. We are committed to providing equal employment opportunities without regard to race, color, religion, sex, pregnancy, childbirth, or related medical condition, national origin, age, veteran status, disability, genetic information, sickle cell trait, marital status, or any other characteristic protected by law.</p><p>
This Equal Employment Opportunity policy governs every aspect of employment, encompassing recruitment, hiring, selection, job assignment, promotions, transfers, compensation, discipline, termination, layoff, access to benefits and training, as well as all other conditions and privileges of employment.</p><p>
At Minds in Motion ABA Therapy Center, LLC, we strongly encourage the reporting of any instances of discrimination and harassment. We strictly prohibit retaliation against any individual who reports discrimination or harassment or participates in an investigation related to such reports. Our organization is committed to taking appropriate disciplinary action, including immediate termination, against any employee found to be in violation of this policy.".</p>
                                                <a href="/pdf/mim-work-application.pdf" target="_blank" class="an-btn mt-1 d-inline-block">Apply Now</a>
                                            </div>

                                        </div>

                                    </div>



                                    <div class="card mb-0">

                                        <div class="card-header" id="headingFour">

                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Diversity &amp; Inclusion <i class="fas fa-plus"></i></button>

                                        </div>

                                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">

                                            <div class="card-body">

                                                <p>At Minds in Motion ABA Therapy Center, we are dedicated to fostering a warm and inclusive environment where everyone is not only welcomed but also encouraged to thrive. Our team at Minds in Motion ABA Therapy Center, LLC leads by example, embodying inclusive behavior, and treating others with unwavering respect, dignity, and empathy in every interaction.</p>
<p>We take pride in cultivating a dynamic workplace where each team member enjoys the freedom to express themselves authentically, fostering a collaborative atmosphere enriched by diverse skills, backgrounds, and experiences. In a world marred by prejudices, hatred, and racism, we empower our team to be agents of change and champions of compassion.</p><p>Championing the rights of both our children and team members, our unwavering commitment extends beyond the boundaries of autism. We are resolute in our mission to forge a community that is not only safer and more resilient but serves as a beacon of inclusivity and compassion for all of humanity.</p>
                                                <a href="/pdf/mim-work-application.pdf" target="_blank" class="an-btn mt-1 d-inline-block">Apply Now</a>
                                            </div>

                                        </div>

                                    </div>



                                </div>

                            </div>



                        </div>

                    </div>

                </div>

            </section>
        </div>
    );
}

export default Careers;