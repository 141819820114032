import React, { useState,useEffect } from 'react';

const Client = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section class="contact-srvcsec">

        <div class="container">

            <div class="row">

                <div class="col-md-12">

                  <iframe src="https://members.centralreach.com/?public=true#forms/form?id=D61509C7-E868-4475-8D3F-7FC87D200BCC" allowtransparency="true" class="client-form" frameborder="0"  width="100%" height="2050"></iframe>

                </div>

            </div>

        </div>

    </section>
  );
}

export default Client;