import React, { useState,useEffect } from 'react';

const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  
  return (
    <div>
        <section class="banner bloging-banner">

            <div class="container">

                <div class="row">

                    <div class="col-md-12">

                        <h2>Blogs</h2>

                    </div>

                </div>

            </div>

        </section>

        <section class="main-blogpag">

            <div class="container">

                <div class="row">

                    <div class="col-md-12">
                        <div class="comng-head">
                            <h2>Coming Soon</h2>
                        </div>
                    </div>

                </div>

            </div>

        </section>
    </div>
  );
}

export default Blog;