import React, { useState, useEffect } from 'react';
import JotformEmbed from 'react-jotform-embed';
import Modal from "react-bootstrap/Modal";

const Team = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <section class="main-teampag">

            <div class="container">

                <div class="row">

                    <div class="col-md-12">

                        <div class="teampag-img">

                            <div class="row align-items-center">

                                <div class="col-lg-6">

                                    <figure>

                                        <img src="images/p-2.png" alt="img" class="img-fluid" />

                                    </figure>

                                </div>

                                <div class="col-lg-6">

                                    <div class="teampag-txt">

                                        <h3>Suzanne Shusteris-Diaz</h3>

                                        <h4>(Executive Director)</h4>

                                        <p>Suzanne, originally from New York, has transitioned from a successful career in banking and finance to a dedicated focus on helping children with Autism in Florida. With over a decade of experience in business management, her financial and business expertise is a valuable asset for establishing strong community relationships and collaborating with partners.<br/>
                                        Her decision to change career paths upon moving to Florida reflects her deep passion for making a meaningful impact on the lives of children with special needs, particularly those with Autism. Suzanne is actively engaged in advocating for the Special Needs Community through her participation on parent and organizational boards.<br/>
                                        Suzanne's academic achievements include a Bachelor's degree in Sociology from Florida Atlantic University, and she is currently pursuing a Master's degree in Special Education. Her journey from the financial sector to a fulfilling career in special education and advocacy showcases her remarkable transformation and significant impact on the lives of those she serves.<br/>
                                        In her free time, Suzanne enjoys spending quality time with her husband, Michael, and their three children, Noah, Abbi, and Hannah. She also has a passion for travel, cooking, and reading.</p>

                                        <div class="socl-icn">

                                            <a href="https://www.facebook.com/mindsinmotionaba" target="_blank"><i class="fab fa-facebook-f"></i></a>

                                            <a href="https://www.instagram.com/mindsinmotionaba/" target="_blank"><i class="fab fa-instagram"></i></a>
                                            

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="teampag-img mb-4">

                            <div class="row align-items-center">

                                <div class="col-lg-4">

                                    <figure>

                                        <img src="images/Kaitlyn.jpg" alt="img" class="img-fluid" />

                                    </figure>

                                </div>

                                <div class="col-lg-6">

                                    <div class="teampag-txt">

                                        <h3>Kaitlyn Simonds</h3>

                                        <h4>(Clinical Director)</h4>

                                        <p>Kaitlyn Simonds, our exceptional Clinical Director at Minds in Motion and a seasoned BCBA, originates from Boston, bringing over 9 years of expertise to our team. Her educational journey boasts a Bachelor's in Psychology, a Bachelor's in Criminal Justice, and a Master's in Applied Behavior Analysis, a testament to her unwavering commitment to evidence-based practices.<br/>
                                        In her pivotal role, Kaitlyn conducts intakes to ensure tailored ABA therapy for each child and family. She oversees the clinical team, facilitates RBT requirements, and guides future BCBAs in their professional development. Noteworthy achievements include creating a comprehensive training program and advocating for community-based clients.<br/>
                                        Specializing in early intervention, social skills, and individualized goal development, Kaitlyn is rooted in Radical Behaviorism, emphasizing ethical and compassionate ABA services. Beyond her professional achievements, she co-created Friends Connect, a social skills group in Westwood, MA, fostering cooperation among neurodivergent and neurotypical children through LEGO building.<br/>
                                        Outside of work, Kaitlyn finds joy in running and biking alongside her fiancé, exploring neuroscience literature, and spending quality time with loved ones. Whether relaxing on the beach or indulging in her passion for cooking, she masterfully balances her dedication to ABA with a vibrant and fulfilling personal life.</p>

                                        <div class="socl-icn">

                                            <a href="https://www.facebook.com/mindsinmotionaba" target="_blank"><i class="fab fa-facebook-f"></i></a>

                                            <a href="https://www.instagram.com/mindsinmotionaba/" target="_blank"><i class="fab fa-instagram"></i></a>

                                            

                                            

                                        </div>

                                    </div>

                                </div>

                            </div>



                        </div>

                        <div class="teampag-img mb-4">

                            <div class="row align-items-center">

                                <div class="col-lg-4">

                                    <figure>

                                        <img src="images/Leidy.jpg" alt="img" class="img-fluid" />

                                    </figure>

                                </div>

                                <div class="col-lg-6">

                                    <div class="teampag-txt">

                                        <h3>Leidy Sims</h3>

                                        <h4>(Administrative Manager)</h4>

                                        <p>Leidy Sims, originally from Cuba, moved to Miami at 12, where her early fascination with Psychology led her to pursue a degree in Applied Behavior Analysis at Florida International University. Graduating with honors in 2021, she now resides in Coconut Creek, Florida, alongside her husband, and serves as the Administrative Manager at Minds in Motion.<br/>
                                        In her pivotal role, Leidy ensures smooth day-to-day operations, focusing on client onboarding and recruitment. She enhances processes, notably the New Client Intake, simplifying onboarding documents for digital completion. With over 6 years of business management experience, Leidy aspires to fuse her love for administrative processes and psychology to grow Minds in Motion and support more children and families.<br/>
                                        Guided by a belief in the transformative power of diligence, compassion, and integrity, Leidy brings a caring and compassionate approach, especially towards the neurodivergent population. Beyond work, she finds inspiration in fantasy, enjoying reading and sketching in her free time.</p>

                                        <div class="socl-icn">

                                            <a href="https://www.facebook.com/mindsinmotionaba" target="_blank"><i class="fab fa-facebook-f"></i></a>

                                            <a href="https://www.instagram.com/mindsinmotionaba/" target="_blank"><i class="fab fa-instagram"></i></a>

                                            

                                            

                                        </div>

                                    </div>

                                </div>

                            </div>



                        </div>

                        <div class="teampag-img mb-4">

                            <div class="row align-items-center">

                                <div class="col-lg-4">

                                    <figure>

                                        <img src="images/Meredith.jpg" alt="img" class="img-fluid" />

                                    </figure>

                                </div>

                                <div class="col-lg-6">

                                    <div class="teampag-txt">

                                        <h3>Meredith Lee Morales</h3>

                                        <h4>(Board Certified Assistant Behavior Analyst)</h4>

                                        <p>Meredith Lee-Morales, a devoted professional residing in the vibrant community of Delray Beach, FL, shares her life with her loving husband and two wonderful children.
Armed with a Bachelor's degree in Psychology and a post-graduate certificate in Applied Behavior Analysis, Meredith is currently in the final stretch of her Master's degree in Applied Behavior Analysis at the prestigious Florida Institute of Technology, further enriching her knowledge and skills in the field.<br/>
Meredith's journey with Minds in Motion ABA commenced in 2020, where she played an integral role as one of the inaugural team members. Throughout her tenure, she has worn many hats, evolving from a Registered Behavior Technician (RBT) to the role of Lead RBT, New Hire Trainer, and, as of 2022, Board Certified Assistant Behavior Analyst (BCaBA).<br/>
With the imminent completion of her Master's degree, she aspires to elevate her certification to BCBA by early 2024. Over the past 6 years, Meredith has demonstrated her unwavering commitment to making a difference in the lives of neurodiverse individuals spanning from toddlers to young adults. Her expertise extends across a spectrum of environments, including schools, clinics, community settings, and home-based services.<br/>
Meredith is a fervent advocate for ethical, compassionate, and person-centered interventions, a philosophy she consistently integrates into her work. Her specialization lies in Early Intervention, where she firmly believes that Play-based therapy is the most potent avenue for nurturing growth. Meredith's core belief is that every child possesses the innate capacity to learn when equipped with the right tools. She brings an authentic blend of empathy, understanding, and fun to every interaction and session, creating an environment where growth and development flourish.<br/>
Beyond her professional endeavors, Meredith finds solace and joy by the beach or out on the water, relishing precious moments with her family.</p>

                                        <div class="socl-icn">

                                            <a href="https://www.facebook.com/mindsinmotionaba" target="_blank"><i class="fab fa-facebook-f"></i></a>

                                            <a href="https://www.instagram.com/mindsinmotionaba/" target="_blank"><i class="fab fa-instagram"></i></a>

                                            

                                            

                                        </div>

                                    </div>

                                </div>

                            </div>



                        </div>

                        <div class="teampag-img mb-4">

                            <div class="row align-items-center">

                                <div class="col-lg-4">

                                    <figure>

                                        <img src="images/Bruna.jpg" alt="img" class="img-fluid" />

                                    </figure>

                                </div>

                                <div class="col-lg-6">

                                    <div class="teampag-txt">

                                        <h3>Bruna Jackson</h3>

                                        <h4>(Board Certified Assistant Behavior Analyst)</h4>

                                        <p>Bruna Jackson, our incredible Board Certified Assistant Behavior Analyst (BCaBA), is a native Floridian, however, maintains a deep connection to her Brazilian roots. Currently pursuing a Master of Arts in Applied Behavior Analysis at the Florida Institute of Technology, Bruna is close to achieving a Board Certified Behavior Analyst (BCBA) credential in Summer 2024.<br/>
With 7 years of combined experience working with children, Bruna's professional journey began as a teacher at a preschool and pivoted into the field of Applied Behavior Analysis. In the three years, Bruna has been with us, she has transitioned through many roles, starting as a Registered Behavior Technician, she progressed to become a Lead RBT, a Trainee/Student Analyst, a New Hire Training Coordinator, a Case Manager, and now our beloved BCaBA. In her current role, Bruna focuses on providing supervision to RBTs, conducting parent training, writing and implementing intervention plans, conducting Functional Behavior Assessments and Skill Acquisition Assessments, providing clinical recommendations and replacement skills based on the assessments, creating protocols, and monitoring their progress. Bruna specializes in Early Intervention, Discrete Trial Teaching (DTT), Natural Environment Teaching (NET), Pivotal Response Training (PRT), and Play-Based Therapy.<br/>
Beyond her professional pursuits, Bruna enjoys singing, exploring new destinations, immersing herself in literature, and cherishing moments with loved ones. Fluent in Portuguese and an adept baker of Brazilian dishes, she now resides in Boca Raton alongside her husband and their puppy, Bella.</p>

                                        <div class="socl-icn">

                                            <a href="https://www.facebook.com/mindsinmotionaba" target="_blank"><i class="fab fa-facebook-f"></i></a>

                                            <a href="https://www.instagram.com/mindsinmotionaba/" target="_blank"><i class="fab fa-instagram"></i></a>

                                            

                                            

                                        </div>

                                    </div>

                                </div>

                            </div>



                        </div>

                        <div class="teampag-img mb-4">

                            <div class="row align-items-center">

                                <div class="col-lg-4">

                                    <figure>

                                        <img src="images/Maria.jpg" alt="img" class="img-fluid" />

                                    </figure>

                                </div>

                                <div class="col-lg-6">

                                    <div class="teampag-txt">

                                        <h3>Maria Coronado</h3>

                                        <h4>(Board Certified Assistant Behavior Analyst )</h4>

                                        <p>Maria A. Coronado exemplifies unwavering dedication to her role in Applied Behavior Analysis. This commitment has been shaped by her inspiring journey from Colombia to the United States, showcasing her relentless pursuit of personal and professional growth.<br/>
Maria, with a Bachelor's degree in Physical Therapy Science, earned in Colombia in 2008, began a new chapter in 2010 upon relocating to the United States. In December 2023, she achieved a significant career milestone by completing her Master's Degree in Behavior Analysis practice. Her diverse background encompasses valuable experiences in U.S. healthcare services and teaching positions, culminating in her current role as a Board Certified Assistant Behavior Analyst (BCaBA) at MIM, to which she has devoted nearly two years. Her ambitious trajectory includes aspirations to attain the Board Certified Behavior Analyst (BCBA) designation in the near future.<br/>
Her approach, characterized by humility, respect, and a focus on individual strengths, values, and motivations, underscores a person-centered and empathetic perspective.<br/>
Maria excels in conducting VB-MAPP assessments and ABBLs (Developmental Assessments). Her expertise extends to implementing programs for acquisition skills, employing methodologies such as Discrete Trial Training (DTT), Naturalistic Environment Teaching, and Pivotal Response Training. Her ultimate aim is to guide children towards achieving functional milestones that foster independence and integration into mainstream settings like school and the community.<br/>
Beyond her professional achievements, Maria takes immense pride in her family – her husband, son, and little girl. Her commitment to maintaining a healthy work-life balance is evident through her dedication to quality time spent with loved ones. Maria finds joy in outdoor activities like dancing, painting, puzzle-solving, biking, and exploring nature.</p>

                                        <div class="socl-icn">

                                            <a href="https://www.facebook.com/mindsinmotionaba" target="_blank"><i class="fab fa-facebook-f"></i></a>

                                            <a href="https://www.instagram.com/mindsinmotionaba/" target="_blank"><i class="fab fa-instagram"></i></a>

                                            

                                            

                                        </div>

                                    </div>

                                </div>

                            </div>



                        </div>

                        <div class="teampag-img mb-4">

                            <div class="row align-items-center">

                                <div class="col-lg-4">

                                    <figure>

                                        <img src="images/Keiry.jpg" alt="img" class="img-fluid" />

                                    </figure>

                                </div>

                                <div class="col-lg-6">

                                    <div class="teampag-txt">

                                        <h3>Keiry De Souza</h3>

                                        <h4>(Scheduler / Administrative Assistant)</h4>

                                        <p>Keiry Ann Gloria De Souza, originally from Brazil, embraced Boca Raton as her home at the age of 11. Currently pursuing a BA in Human Resource Management, Keiry serves as our Scheduler and Administrative Assistant at Minds in Motion.<br/>
With 7 years of professional experience in administration, Keiry excels beyond managing schedules, actively contributing to various administrative responsibilities. Her commitment to professionalism, confidentiality, and organization ensures the seamless functioning of the organization.<br/>
Outside of work, Keiry finds joy in playing tennis, crafting earrings, and relishing moments with family, friends, and her beloved doggies. She cherishes her role as an aunt to her close friend's children.</p>

                                        <div class="socl-icn">

                                            <a href="https://www.facebook.com/mindsinmotionaba" target="_blank"><i class="fab fa-facebook-f"></i></a>

                                            <a href="https://www.instagram.com/mindsinmotionaba/" target="_blank"><i class="fab fa-instagram"></i></a>

                                            

                                            

                                        </div>

                                    </div>

                                </div>

                            </div>



                        </div>

                    </div>
                    <div class="col-12 text-center mt-3 mt-xl-5">
                        <a class="an-btn" onClick={handleShow}>
                            Join Our Team
                        </a>
                        <Modal show={show} onHide={handleClose} dialogClassName="team-modal">
                            <Modal.Header closeButton>
                                <Modal.Title>Join Our Team</Modal.Title>
                            </Modal.Header>
                            <Modal.Body class="p-0"><JotformEmbed src="https://form.jotformeu.com/211965201422042" /></Modal.Body>
                        </Modal>
                    </div>
                </div>

            </div>

        </section>
    );
}

export default Team;