import React, { useState,useEffect } from 'react';

const Referrals = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>

            <section class="main-carerpag">

                <div class="container">

                    <div class="row">

                        <div class="col-md-12">

                            <div class="carerpag-txt">

                                <h2>Looking to build a referral partnership with Minds in Motion ABA Therapy?</h2>

                                <p class="pr-0 mailTxt"><br/>
<b>Leidy Sims</b><br/>
Administrative Manager<br/>
<a href="tel:786-717-9643">786-717-9643</a><br/>
<a href="mailto:leidy@mindsinmotionaba.com">leidy@mindsinmotionaba.com</a><br/><br/>

General Info:<br/><a href="tel:954-988-0918">954-988-0918</a><br/>
<a href="mailto:info@mindsinmotionaba.com">info@mindsinmotionaba.com</a><br/><br/>
Fax:<br/><a href="tel:954-827-7625">954-827-7625</a>
</p>

                            </div>



                        </div>

                    </div>

                </div>

            </section>
        </div>
    );
}

export default Referrals;