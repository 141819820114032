import React, { useState,useEffect } from 'react';

const Resources = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <section class="banner">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Resources</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section class="main-resrcpag">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4">
                            <div class="rsrc-bx">
                                <img src="images/img25.png" class="img-fluid" alt="img" />
                                <h3>PARENTS &amp; PROFESSIONALS</h3>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="rsrc-bx">
                                <img src="images/img26.png" class="img-fluid" alt="img" />
                                <h3>IPAD APPS</h3>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="rsrc-bx">
                                <img src="images/img27.png" class="img-fluid" alt="img" />
                                <h3>LOCAL AREA SERVICES</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Resources;