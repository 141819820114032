import React, { useState,useEffect } from 'react';

const Social = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <section class="main-srvcpag">

            <div class="container">

                <div class="row align-items-top">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <img src="images/social-1.jpg" class="img-fluid" alt="img" />
                    </div>
                    <div class="col-lg-6">
                        <div class="srvcpag-txt">
                            <h2>Social Skills Group</h2>
                            <p>Some kids learn to wait their turn by watching others, however some kids with learning and thinking differences might not pick up on common social interactions simply through observation. For some kids with special needs playing with others may not come easily. If your child struggles with social skills, a social skills group could help. In MIM Social Skills Groups, we work with your children to help strengthen their ability to build social relationships as well as play appropriately. This is achieved by developing essential social skills, such as:</p>
                            <br />
                            <ul>
                                <li>•		Ability to make friends</li>
                                <li>•		Ability to communicate with peers</li>
                                <li>•		Ability to share</li>
                                <li>•		Ability to engage in joint attention</li>
                                <li>•		Ability to develop eye contact</li>
                                <li>•		Ability to listen and take turns</li>
                                <li>•		Ability to play together</li>
                                <li>•		Ability to start a conversation-or keep one going</li>
                                <li>•		Ability to understand body language</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row align-items-top">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <img src="images/social-2.jpg" class="img-fluid" alt="img" />
                    </div>
                    <div class="col-lg-6">
                        <div class="srvcpag-txt">
                            <p>Social Skills Groups provide your child with the skills necessary to engage and play with their peers. The BCBA’s as well as team of highly skilled and trained RBT’s act as facilitators and ensure that each child is learning and practicing these very important life skills.</p>
                            <br />
                            <p>Parents don’t need to stay for the session, and most insurance carriers will cover this service. Progressive also offers the option of private pay.</p>
                            <br />
                            <p>For your child to be able to participate in our Social Skills Group they must be able to engage in a group setting for a minimum of 5 minutes without disruptive behaviors, follow simple directions, and able to use at least 3-word sentences.</p>
                        </div>
                    </div>
                </div>


                <div class="row align-items-top mb-4">
                    <div class="col-lg-12 col-sm-12">
                        <div class="srvcpag-txt">
                            <h2>Join us for MIM Moments Play Groups!</h2>

                            <p class="">Call <a href="tel:954-988-0918">954-988-0918</a> or email <a href="mailto:info@mindsinmotionaba.com">info@mindsinmotionaba.com</a> to check availability!</p>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    );
}

export default Social;