import React, { useState,useEffect } from 'react';

const Covid = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <section class="banner covid-19-banner">

                <div class="container">

                    <div class="row">

                        <div class="col-md-12">

                            <h2>Covid-19 Policy</h2>

                        </div>

                    </div>

                </div>

            </section>


            <section class="main-cvdpolcy">

                <div class="container">

                    <div class="row">

                        <div class="col-md-12">

                            <div class="cvdpolcy-txt pb-0">

                                <h2>COVID-19 Safety Protocols</h2>

                                <h3>The safety of our children and staff is of the utmost importance. At Minds in Motion ABA Therapy Center, LLC we strictly follow and enforce the guidelines and protocols set forth by the Center for Disease Control and Prevention (CDC) and the Florida Health Department. We learn more about COVID-19 every day, and as more information becomes available Minds in Motion ABA Therapy Center, LLC will continuously update our policy and practices to ensure the health and safety of our clients and staff.  </h3>

                                <p>During this unprecedented time, we are asking that both the Minds in Motion team and our families work together and collaboratively with one another to help contain the spread of the virus. We ask our families:</p>

                                <ul>

                                    <li>•		Keep your child at home should they display symptoms of illness</li>

                                    <li>•		If your child has a fever, runny nose, or cough please contact your pediatrician (we will need a return to school form signed)</li>

                                    <li>•		Wash your hands, and encourage your child to wash their hands frequently throughout the day. Handwashing should last for 20 seconds. Children should be encouraged to wash hands thoroughly: after coughing, sneezing, before eating, after using the bathroom. Washing with soap and water is idea, however should that not be available, using an alcohol-based sanitizer with at least 70% alcohol is recommended. </li>

                                    <li>•		Avoid contact with people who are sick, keep a minimum of 6 feet social distance </li>

                                    <li>•		Avoid touching your mouth, nose, and eyes </li>

                                    <li>•		Regularly clean child’s toys, surfaces that have been touched frequently at home, and toys </li>

                                </ul>

                                <p>Within the Center we are following very rigorous protocols to ensure the safety of your children and our staff. </p>

                                <ul>

                                    <li>•	Daily Health checks, Staff, Children, and Visitors will all be screened upon entering the facility. We use a contactless thermometer. NO ONE will be admitted into the Center with a temperature above 99.6 or greater</li>

                                    <li>•	Staff and Children will NOT be admitted into the Center should they display symptoms of: Fever or chills, cough, runny nose, shortness of breath, fatigue, headache, new loss of taste of smell, sore throat, congestion, vomiting or nausea, or diarrhea. </li>

                                    <li>•	We will ask staff and facilities to attest that they have not had ANY signs, symptoms or contact with COVID-19 symptoms or infected people </li>

                                    <li>•	If your child isn’t feeling well, they must remain at home </li>

                                    <li>•	If there is a COVID-19 illness in the family, the child will not be able to return for 14 days</li>

                                    <li>•		Should there be a positive case in the classroom, the entire classroom will close for 14 days </li>

                                    <li>•		Children will be screened periodically, throughout the day. Should a child begin to exhibit symptoms listed above, we will segregate the child until a parent comes to pick them up (we allow 30 minutes, or we request an emergency contact to be called)</li>

                                    <li>•		Children must be fever/symptom free for 72 hours before returning to the Center</li>

                                    <li>•		Should a staff member become ill, he/she will be sent home immediately. The above-mentioned rules apply for staff. </li>

                                    <li>•		All staff members will wear protective face masks, until further notice </li>

                                    <li>•		We have installed UV germicidal lights in all of our A/C units that destroy viruses, bacteria, mold fungi, and mildew </li>

                                    <li>•		MIM will routinely clean, sanitize, and disinfect surfaces and objects that are frequently touched, specifically toys and surfaces. This includes, but is not limited to cleaning objects/surfaces such as doorknobs, light switches, sink handles, countertops, toilet training potties, desks, chairs, cubbies, and playground equipment and structures</li>

                                    <li>•	We also request all parents and staff disclose any travel plans or contact with anyone who has recently traveled so we can take proper safety precautions</li>

                                </ul>

                                <p>Health Policy, Procedures & Personal Protective Equipment (PPE)</p>

                                <ul>

                                    <li>•	Parents will sanitize your child’s hands before entering the Center</li>

                                    <li>•	Handwashing will be enforced and required throughout the day

                                        <ul>

                                            <li>o	Entering the Center </li>

                                            <li>o	Before and after snacks/meals </li>

                                            <li>o	After blowing one’s nose/coughing/sneezing </li>

                                            <li>o	Before and After gym play </li>

                                        </ul>

                                    </li>

                                    <li>•	Children will NOT be required to wear a mask unless a parent has specifically requested </li>

                                    <li>•	Staff will be required to wear a mask at all times, except in Kitchen during lunch

                                    </li>

                                </ul>

                                <p>Should a case of COVID-19 arise at the Center to following precautions will be taken:</p>

                                <ul>

                                    <li>•	We will immediately coordinate with Florida Health Officials to help develop a course of action to be taken in the Center</li>

                                    <li>•	Communicate with parents and staff immediately

                                    </li>

                                    <li>•	Clean and disinfect the entire center to minimize potential of respiratory droplets. Classrooms will be fogged with an disinfectant  </li>

                                </ul>

                                <p>Additional information can be found on the CDC website:</p>

                                <ul>

                                    <li><a href="https://www.cdc.gov/">https://www.cdc.gov/</a></li>

                                    <li><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html">https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html</a></li>

                                    <li><a href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/workplace-school-and-home-guidance.pdf">https://www.cdc.gov/coronavirus/2019-ncov/downloads/workplace-school-and-home-guidance.pdf</a></li>

                                </ul>

                                <p>The safety of your children and our staff is a responsibility that we take very seriously. We hope that all this comes to a speedy end soon, but in the meantime, we want to ensure the successful implementation of these safety protocols to protect our families. If you need any further clarification, or should you wish discuss any other of the abovementioned, please always feel free to call our center or send us an e-mail. We will happily answer any question or concern you might have.</p>

                                <span>Thank you, </span><br />

                                <span>Minds in Motion ABA Therapy Center

                                </span>

                            </div>

                        </div>

                    </div>

                </div>

            </section>

        </div>
    );
}

export default Covid;