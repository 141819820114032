import React, { useState, useEffect } from 'react';
import {
    Link
} from "react-router-dom";
import Slider from 'react-slick';
import JotformEmbed from 'react-jotform-embed';
import Modal from "react-bootstrap/Modal";
import 'react-vertical-timeline-component/style.min.css';
import GoogleMaps from '../Component/Map';
import $ from 'jquery';

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4
            }
        }

    ]
};

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);

        //highlight navigation
        $('.progress-bar--increment').css('max-height', $('.progress-bar').height())
        $(window).scroll(function () {

            var windowpos = $(window).scrollTop();
            var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

            console.log(scrollPosition);
            
            if($('.steps-wrap').length) {
            var height = $('.steps-wrap').height();
            var offsetTop = $('.steps-wrap').offset().top - (height / 3);
            var scrollProgress = 0;

            if (scrollPosition >= offsetTop) {
                scrollProgress = scrollPosition - offsetTop - 100;
            } else {
                scrollProgress = 0;
            }

            $('.progress-bar--increment').css('height', scrollProgress + 'px');

            var progress = $('.progress-bar--increment').offset().top + $('.progress-bar--increment').height();

            $('.step-wrap').each(function (i) {
                var clientHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                var rect = this.getBoundingClientRect();
                var inViewport = (
                    rect.top >= (clientHeight / 3) &&
                    rect.top <= (clientHeight)
                );

                if (inViewport) {
                    $('.steps-wrap .icon').each(function (j) {
                        if (j < i) {
                            return;
                        }

                        $(this).removeClass('active')
                    });
                }

                if (i === 0) {
                    $(this).find('.icon').addClass('active');
                    return;
                }

                if (progress >= $(this).offset().top) {
                    $(this).find('.icon').addClass('active');
                }
            });
        }

        });

    }, [])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div class="main-section">
            <div class="main-slider">
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleControls" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleControls" data-slide-to="1"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active wow fadeInLeft" data-wow-delay="0.5s">
                            <img class="img-fluid w-100" src="images/banner.jpg" class="w-100" alt="First slide" />
                            <div class="carousel-caption">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="slide-txt">
                                                <h2>Mission</h2>
                                                <p>Our mission is to deliver personalized, client-centered, compassionate, and evidence-based treatment tailored to children aged one to 10 years old. Dedicated to empowering our clients, families, and communities to achieve their utmost potential, we cultivate an environment enriched with evidence based science, kindness, and care. MIM is unwavering in its commitment to supporting children and their families, fostering growth, and unlocking new possibilities through applying evidence-based principles in Applied Behavior Analysis. At MIM, we empower each child to embrace progress and &quot;Keep Moving Forward.&quot;</p>
                                                <a href="mailto:info@mindsinmotionaba.com" className="an-btn light mr-3">Contact</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid w-100" src="images/slide2.jpg" class="w-100" alt="First slide" />
                            <div class="carousel-caption">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="slide-txt">
                                                <h2>Core Beliefs </h2>
                                                <ul>
                                                    <li>Clients come first</li>
                                                    <li>Learning and movement go hand in hand</li>
                                                    <li>Early intervention is crucial to the acquisition of skills</li>
                                                    <li>Collaboration is imperative</li>
                                                    <li>Professionals must be respectful, knowledgeable &amp; compassionate</li>
                                                </ul>
                                                <button class="an-btn" onclick="window.location.href='/client'">Contact</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <i class="fal fa-angle-left"></i>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <i class="fal fa-angle-right"></i>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>

            <section class="main-aplidsec">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="aplidsec-txt">
                                <h2>Minds in Motion</h2>
                                <p>Minds in Motion ABA Therapy Center, LLC stands at the forefront of Applied Behavioral Analysis, specializing in personalized programs for children with Autism and related developmental disabilities. Our commitment is rooted in utilizing cutting-edge, evidence-based, compassionate care methodologies. At MIM, our unwavering dedication unfolds within a clinic environment that mirrors a preschool setting, where we tirelessly engage with our young learners. Our support extends beyond the clinic walls, reaching into the home, community, and seamlessly collaborating with your child's educational institutions-both public and private.</p><p>Our mission is clear: to empower every child and their family with the essential skills to unlock their maximum potential.</p>
                                <Link to="/about" className="an-btn">Read more</Link>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="aplidsec-img">
                                <img src="images/img2.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="main-srvcsec">
                <div class="container">
                    <div class="srvcsec-head">
                        <h2>Services We Offer</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div class="service-wrap">
                                <img src="images/img15.jpg" class="img-fluid" alt="Direct Assessments" />
                                <h4>Direct Assessments</h4>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div class="service-wrap">
                                <img src="images/img16.jpg" class="img-fluid" alt="Indirect Assessments" />
                                <h4>Indirect Assessments</h4>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div class="service-wrap">
                                <img src="images/img17.jpg" class="img-fluid" alt="Practical Functional Assessment (PFA) and Skill Based Treatment (SBT)" />
                                <h4>Practical Functional Assessment (PFA) and Skill Based Treatment (SBT)</h4>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 mb-4 mb-xl-0">
                            <div class="service-wrap">
                                <img src="images/img34.jpg" class="img-fluid" alt="Comprehensive treatment plan to inform skills to decrease and increase" />
                                <h4>Comprehensive treatment plan to inform skills to decrease and increase</h4>
                            </div>
                        </div>
                        <div class="col-12 text-center mt-3 mt-xl-5">

                            <Link to="/services" className="an-btn">Read more</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section class="main-resrcs bg-white">
                <div class="container">
                    <div class="col-12">
                        <div class="srvcsec-head">
                            <h2 class="mb-4">Insurances We Accept</h2>
                        </div>
                        <Slider {...settings}>
                            <div class="item">
                                <img src="images/united-healthcare.png" />
                            </div>
                            {/* <div class="item">
                                <img src="images/optum.png" />
                            </div> */}
                            <div class="item">
                                <img src="images/ins-1.png" />
                            </div>
                            <div class="item">
                                <img src="images/ins-2.png" />
                            </div>
                            <div class="item">
                                <img src="images/ins-3.png" />
                            </div>
                            <div class="item">
                                <img src="images/ins-4.png" />
                            </div>
                            <div class="item">
                                <img src="images/ins-5.png" />
                            </div>
                            <div class="item">
                                <img src="images/ins-6.png" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>

            <section class="main-resrcs our-process-section scroll-trigger">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="srvcsec-head">
                                <h2 class="mb-4 text-white">Our Process</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="process-wrap">
                                <div class="steps-wrap">
                                    <div class="progress-bar"></div>
                                    <div class="progress-bar--increment" ></div>
                                    <div class="step-wrap" id="step1">
                                        <div class="icon">
                                            <span>1.</span>
                                        </div>
                                        <div class="step-info">
                                            <h3>Intake - Application</h3>
                                            <div class="font-18">
                                                <p>After your initial inquiry, our team will send you a welcome email with information about MIM and important paperwork for you to fill out.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step-wrap" id="step2">
                                        <div class="icon">
                                            <span>2.</span>
                                        </div>
                                        <div class="step-info">
                                            <h3>Verification of Benefits</h3>
                                            <div class="font-18">
                                                <p>Once your ABA Benefits are verified, and should 3 you decide to move forward with receiving ABA, we will request authorization for an initial assessment to be conducted with a BCBA and you will receive our Clinical & Admin Intake packets.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step-wrap" id="step3">
                                        <div class="icon">
                                            <span>3.</span>
                                        </div>
                                        <div class="step-info">
                                            <h3>Authorization Request</h3>
                                            <div class="font-18">
                                                <p>Once your ABA Benefits are verified, and should 3 you decide to move forward with receiving ABA, we will request authorization for an initial assessment to be conducted with a BCBA and you will receive our Clinical & Admin Intake packets.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step-wrap" id="step4">
                                        <div class="icon">
                                            <span>4.</span>
                                        </div>
                                        <div class="step-info">
                                            <h3>Initial Assessment</h3>
                                            <div class="font-18">
                                                <p>After we receive the clinical and admin intake packets and after we receive approval from your insurance to conduct an initial assessment, a BCBA will reach out to schedule a time to conduct the assessment.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step-wrap" id="step5">
                                        <div class="icon">
                                            <span>5.</span>
                                        </div>
                                        <div class="step-info">
                                            <h3>Treatment Recommendations</h3>
                                            <div class="font-18">
                                                <p>After your initial assessment with the BCBA, the BCBA will develop an individualized plan that 5 addresses your child's needs. Once your BCBA completes this plan and after you review the plan, we will submit this plan along with a request to begin ABA services to your insurance Company.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step-wrap" id="step6">
                                        <div class="icon">
                                            <span>6.</span>
                                        </div>
                                        <div class="step-info">
                                            <h3>Authorization for ABA Services</h3>
                                            <div class="font-18">
                                                <p>While we wait to receive approval from your insurance, a BCBA will schedule a free meeting to discuss the plan, what services will look like and to learn more about your child's interests.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step-wrap" id="step7">
                                        <div class="icon">
                                            <span>7.</span>
                                        </div>
                                        <div class="step-info">
                                            <h3>Setting Schedule</h3>
                                            <div class="font-18">
                                                <p>While we wait for authorization we will work 7 collaboratively with you to create a schedule based on your child's availability and clinically recommended treatment hours.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step-wrap" id="step8">
                                        <div class="icon">
                                            <span>8.</span>
                                        </div>
                                        <div class="step-info">
                                            <h3>Starting ABA Services</h3>
                                            <div class="font-18">
                                                <p>Once we receive approval to start services, services will start with our MIM team building a meaningful connection with your child! This process consists of high quality 1:1 attention being provided by our highly trained Registered Behavior Therapist (RBT) while your child leads.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step-wrap" id="step9">
                                        <div class="icon">
                                            <span>&hearts;</span>
                                        </div>
                                        <div class="step-info">
                                            <h3>Tour of the Facility</h3>
                                            <div class="font-18">
                                                <p>Although you will receive a tour of our facility when you come in for the initial assessment, you're welcome to request an additional tour at any point during the process!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section class="main-teamsec">
                <div class="container">
                    <div class="srvcsec-head">
                        <h2>Our Team</h2>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-sm-6 col-xl-4 col-lg-4 col-sm-6">
                            <div class="teamsec-bx">
                                <figure>
                                    <img src="images/p-2.png" class="img-fluid" alt="img" />
                                </figure>
                                <div class="team-txt">
                                    <h3>Suzanne Shusteris-Diaz</h3>
                                    <h4>(Executive Director)</h4>
                                    <div class="socl-icn">
                                        <a href="https://www.facebook.com/mindsinmotionaba" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                        <a href="https://www.instagram.com/mindsinmotionaba/" target="_blank"><i class="fab fa-instagram"></i></a>

                                    </div>
                                    <div class="arow-tem">
                                        <a href="/team"><i class="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 col-lg-4 col-sm-6">
                            <div class="teamsec-bx mb-mobile-0">
                                <figure>
                                    <img src="images/Kaitlyn.jpg" class="img-fluid" alt="img" />
                                </figure>
                                <div class="team-txt">
                                    <h3>Kaitlyn Simonds</h3>
                                    <h4>(Clinical Director)</h4>
                                    <div class="socl-icn">
                                        <a href="https://www.facebook.com/mindsinmotionaba" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                        <a href="https://www.instagram.com/mindsinmotionaba/" target="_blank"><i class="fab fa-instagram"></i></a>

                                    </div>
                                    <div class="arow-tem">
                                        <a href="/team"><i class="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 text-center mt-0 mt-sm-5">
                            <a class="an-btn" onClick={handleShow}>
                                Join Our Team
                            </a>
                            <Modal show={show} onHide={handleClose} dialogClassName="team-modal">
                                <Modal.Header closeButton>
                                    <Modal.Title>Join Our Team</Modal.Title>
                                </Modal.Header>
                                <Modal.Body class="p-0"><JotformEmbed src="https://form.jotformeu.com/211965201422042" /></Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </section>
            <section class="main-aplidsec">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-sm-6 col-xl-6 col-lg-6 col-sm-6">
                            <div class="teamsec-bx mb-mobile-0">
                                <img src="images/excellence.webp" class="img-fluid" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="map-section">
                <GoogleMaps />
            </section>
        </div>
    );
}

export default Home;