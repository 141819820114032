import React, { useState,useEffect } from 'react';

const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <section class="main-srvcpag">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <img src="images/img15.jpg" class="img-fluid" alt="img" />
                    </div>
                    <div class="col-lg-6">
                        <div class="srvcpag-txt">
                            <h2>Direct Assessments</h2>

                            <p>Communication assessments to support receptive and expressive language: Verbal Behavior Milestone Placement Program (VBMAPP), The Assessment of Basic Language and Learning Skills (ABLLS-R)</p><br/>
                            <p>Social skills: Socially savvy, Autism Social Skills Profile-2 (ASSP-2)</p><br/>
                            <p>Daily living and safety skills: The Assessment of Functional Living Skills (AFLS)</p>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <img src="images/img16.jpg" class="img-fluid" alt="img" />
                    </div>
                    <div class="col-lg-6">
                        <div class="srvcpag-txt">
                            <h2>Indirect Assessments</h2>
                            <p>Maladaptive behavior: Motivation assessment scale (MAS), Functional Analysis Screening Tool (FAST)</p><br/>
                            <p>Adaptive behavior scale: Vineland-3</p>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <img src="images/img17.jpg" class="img-fluid" alt="img" />
                    </div>
                    <div class="col-lg-6">
                        <div class="srvcpag-txt">
                            <p>Practical Functional Assessment (PFA) and Skill Based Treatment (SBT)</p><br/>
                            <p>Comprehensive treatment plan to inform skills to decrease and increase</p><br/>
                            <p> Parent coaching, consultation and empowerment</p>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mb-4">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <img src="images/img34.jpg" class="img-fluid" alt="img" />
                    </div>
                    <div class="col-lg-6">
                        <div class="srvcpag-txt">
                            <p>Educator consultation and training</p><br/>
                            <p>1:1 direct services with a registered behavior technician (RBT)</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Services;