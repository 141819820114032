import React, { useState, useEffect } from 'react';

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <section class="banner about-banner">

                <div class="container">

                    <div class="row">

                        <div class="col-md-12">

                            <h2>About Us</h2>

                        </div>

                    </div>

                </div>

            </section>
            <section class="main-abtsec">

                <div class="container">

                    <div class="row">
                        <div class="col-12">
                            <div class="abtsec-txt">
                                <h2>Applied Behavior Analysis</h2>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">

                            <div class="abtsec-txt">

                                <p>We strive to create a stimulating environment for our children in a safe and secure atmosphere. Our mission is to provide direct Applied Behavioral Analysis (ABA) services by qualified Board-Certified Behavior Analysts (BCBAs), Registered Behavior Technicians (RBTs) and support staff. We offer a wide array of services to fully meet the needs of each child. Our passion is to empower every child to reach their full potential with effective and results driven intervention.</p>

                            </div>

                        </div>

                        <div class="col-lg-6 col-md-6">

                            <div class="abtsec-txt">

                                <p>At Minds in Motion ABA Therapy Center we are a multidisciplinary center-based model, specializing in early intensive intervention, it is because of this cohesion of services under one roof, we are effectively able to create an individualized plan to ensure the most profound impact on every child’s stage of developmental growth. We strive to use the most cutting-edge technology in delivering the highest quality of service, the first of its kind to be offered in South Florida.</p>

                            </div>

                        </div>

                    </div>

                    <div class="row mt-4 mt-md-5">

                        <div class="col-lg-6">

                            <div class="abt-two mb-4 mb-lg-0">

                                <img src="images/updatedimg1.jpg" class="img-fluid br-15" alt="img" />

                            </div>

                        </div>

                        <div class="col-lg-6">

                            <div class="abt-two">

                                <p>No matter where you are on your journey, Minds in Motion ABA Center, LLC is here for you every step of the way, from diagnosis to teaching families how to master a variety of skills in and out of the home. We are your total pre-school solution that will support your child and family through middle school. </p>

                                <img class="w-100" src="images/img14.jpg" alt="img" />

                            </div>

                        </div>

                    </div>

                </div>

            </section>
        </div>
    );
}

export default About;